import React, { useState, useEffect, Fragment } from "react";
import SearchResultPage from "../../templates/search-results-template";
// import {
//     parseSearchUrl,
//     savedSearchParams,
//     propertyH1
// } from "../../../Components/common/site/utils";

import { UserObjectStoreProvider, useAuthState, getUserobjectData } from "@starberry/myaccount-website-utils";


const SearchResultSales = (props) => {

    useEffect(() => {
        if (isAuthenticated) {
          const getUserObjects = async () => {
              try {
                  const userObjects = await getUserobjectData()
                  setUserObjects(userObjects.data.data)
              } catch (e) {
                  console.log("e:", e)
              }
          }
          getUserObjects()
        }
    }, [])

    const [userObjects, setUserObjects] = useState({});
    const { state: authState, services: authServices } = useAuthState()
    const isAuthenticated = authServices.isAuthenticated()

    

    const fullpath = props.location.pathname
    const location = props.location
    const fullpathone = props.location
    // const searchParams = parseSearchUrl("sales", fullpath);

    return (
        <React.Fragment>
            <SearchResultPage 
                locationname="/properties-for-sale/" 
                pcategorytype="residential" 
                ptype="sales" 
                ptypetag="for-sale" 
                //pstatustype="For Sale" 
                pstatustype= {["For Sale", 'Exchanged','Under Offer']}
                status="For Sale"
                fullpathone={fullpathone} 
                fullpathname={fullpath}
                {...props}
                userObjects={userObjects}
                location={location}
            />
        </React.Fragment>
    );
};

// export default SearchResultSales;

const SearchResultComp = (props) => {
    return(
        <UserObjectStoreProvider>
            <SearchResultSales {...props} />
        </UserObjectStoreProvider>
    )
}


export default SearchResultComp;
